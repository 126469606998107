export const PrivacyPolicies = (props) => {
    return (
        <div className="plan" id="amc" style={{ marginTop: '25vh' }}>
            <div className="container">
                <div className="jumbo-box2 cd">
                    <h2> <b>Privacy Policy</b></h2>
                    <p>At RO Service India, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
                    </p>
                    
                    <h3><b><u>1. Information We Collect</u></b></h3>
                    <p><b>We may collect the following types of information:</b></p>
                    <p><b>Personal Information:</b> When you fill out forms on our site, you may provide personal information such as your name, email address, phone number, and address.</p>
                    <p><b>Usage Data:</b> We may collect information about how you access and use our website, including your IP address, browser type, and pages visited.</p>
                    <p><b>Cookies:</b> Our website may use cookies to enhance user experience. You can manage cookie preferences through your browser settings.</p>

                    <h3><b><u>2. How We Use Your Information</u></b></h3>
                    <p><b>We may use the information we collect for various purposes, including:</b></p>
                    <p><b>-</b>To provide and maintain our services.</p>
                    <p><b>-</b>To communicate with you about your inquiries and service requests.</p>
                    <p><b>-</b>To improve our website and services.</p>
                    <p><b>-</b>To send you promotional materials and updates, if you have opted to receive them.</p>
                    <p><b>-</b>To comply with legal obligations.</p>

                    <h3><b><u>3. Disclosure of Your Information</u></b></h3>
                    <p><b>We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:</b></p>
                    <p><b>Service Providers:</b> We may employ third-party companies and individuals to facilitate our services, and they may have access to your information to perform tasks on our behalf.</p>
                    <p><b>Legal Requirements:</b> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>

                    <h3><b><u>4. Data Security</u></b></h3>
                    <p>We take the security of your personal information seriously and use reasonable measures to protect it. However, please be aware that no method of transmission over the Internet or electronic storage is 100% secure.</p>

                    <h3><b><u>5. Your Rights</u></b></h3>
                    <p><b>You have the right to:</b></p>
                    <p><b>-</b>Access the personal information we hold about you.</p>
                    <p><b>-</b>Request correction of any inaccurate or incomplete information</p>
                    <p><b>-</b>Request deletion of your personal data, subject to certain exceptions</p>
                    <p><b>-</b>To exercise these rights, please contact us using the information provided below.</p>

                    <h3><b><u>6. Links to Other Websites</u></b></h3>
                    <p>Our website may contain links to external sites. We are not responsible for the privacy practices of those sites. We encourage you to read their privacy policies.</p>

                    <h3><b><u>7. Changes to This Privacy Policy</u></b></h3>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review this policy periodically for any updates.</p>

                    <h3><b><u>Contact Us</u></b></h3>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <p><b>RO Service India</b><br /></p>
                    <p><i className='fa fa-envelope-o'></i> Email: <a href="mailto: mail2roserviceindia@gmail.com">mail2roserviceindia@gmail.com </a><br /><i className='fa fa-phone'></i> Mobile: <a href="tel: 18005710106">18005710106</a></p>

                </div>

            </div>
        </div>

    );
};
